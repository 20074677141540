.right-aligned-content {
  justify-content: flex-end;
  margin-left: auto;
}


.red-asterisk {
  color: red;
}


.MuiFormLabel-asterisk .MuiInputLabel-asterisk .css-wgai2y-MuiFormLabel-asterisk {
  color: red !important;
}

.img-xl {
  margin-right: 8px;
}

.width {
  max-width: 1400px;
}

@media (min-width: 1400px) {
  .img-xl {
    margin-right: 20px;
  }
}

@media (min-width: 1600px) {
  .width {
    max-width: 1600px;
  }
}

@-moz-document url-prefix() {
  .stack-firefox {
    width: 40% !important;
  }
}